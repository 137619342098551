/* .expanded-row {
  background-color: 'white';
  border: '5px';
  block-size: 100px;
} */

.expanded-row {
  background-color: white;
  border: 2px solid gray; /* Replace 'yourBorderColor' with the desired border color */
  height: 100px; /* Use 'height' instead of 'block-size' */
}


.deleteicon {
  color: red;
  size: 14;
}

/* .updownarrow {
  color: black;
  size: 10px;
} */

.editicon {
  color: blue;
  size: 15;
}

.viewicon {
  color: blue;
  size: 15;
}





.menu {
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 1;
  background-color: #111111;
  transition: .2s
}

.menu ul {
  border-top: 1px solid #636366;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  border-bottom: 1px solid #636366;
  font-family: 'Open Sans', sans-serif;
  line-height: 45px;
  padding-bottom: 3px;
  padding-left: 20px;
  padding-top: 3px;
}

.menu li a {
  color: white;
}

.bar {
  right: 0px;
}

body,
html {
  height: 100%;
  width: 100%;
}


/* Add this to your CSS file */
@media (max-width: 768px) {
  .email-column, .link-column {
    display: none;
  }
}


