.container-div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 3rem;
    justify-content: center;
    align-items: center;
    
  }

  .card-body-signin {
    display: flex;
    justify-content: center;
  }

  .card-footer-signin {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .image-style {
    width: 10rem;
    height: 12em;
    object-fit: cover;
  }

  /* Media query for larger screens */
  @media (min-width: 768px) {
    .card-container {
      flex-direction: row;
    }
    
    .card-container > div {
      flex: 1; /* Allows items to grow and share the available space equally */
    }
  }
  
